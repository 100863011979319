import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { MsalGuard } from '@azure/msal-angular';
import { EndUserSessionComponent } from './shared/components/end-user-session/end-user-session.component';
import { END_SESSION_PATH } from './features/tvd-setup/constant/tvd-constant';
import { AppAccessGuard } from './core/guards/app-access.guard';
import { UnauthorizedUserComponent } from './components/unauthorized-user/unauthorized-user.component';

const routes: Routes = [
  {
    path: '',
    canLoad: [AppAccessGuard],
    canActivate: [MsalGuard],
    loadChildren: () => import('./features/tvd-setup/tvd-setup.module').then(tvd => tvd.TvdSetupModule),
  },
  {
    path: END_SESSION_PATH,
    component: EndUserSessionComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedUserComponent,
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
